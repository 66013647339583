import React, { ReactElement } from "react";

function PlantPotIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.901"
      height="30"
      viewBox="0 0 25.901 30"
    >
      <g id="plant_1_" data-name="plant (1)" transform="translate(-32.074)">
        <path
          id="Path_9977"
          data-name="Path 9977"
          d="M218.622,21.925a.682.682,0,0,0-.63-.421c-5.115,0-9.781,1.613-12.48,4.312a6.258,6.258,0,0,0,8.85,8.85c1.167-1.167,1.521-3.322,1.9-5.6.409-2.454.818-5,2.215-6.395A.681.681,0,0,0,218.622,21.925Z"
          transform="translate(-160.699 -20.13)"
          fill="#68b030"
        />
        <path
          id="Path_9978"
          data-name="Path 9978"
          d="M131.7,108.64a.682.682,0,0,1-.66-.513c-.769-3.007-3.117-11.161-4.727-12.771a.682.682,0,0,1,.964-.964c1.606,1.606,3.469,7.568,4.438,11.011a27.04,27.04,0,0,1,6.32-10.195.682.682,0,1,1,.98.947l-.017.017a25.589,25.589,0,0,0-6.628,11.914.682.682,0,0,1-.649.553H131.7Z"
          transform="translate(-88.039 -88.182)"
          fill="#007a49"
        />
        <path
          id="Path_9979"
          data-name="Path 9979"
          d="M92.356,299.11a.682.682,0,0,0-.545-.273H75.453a.682.682,0,0,0-.654.87l2.726,9.542a.682.682,0,0,0,.654.493H89.084a.682.682,0,0,0,.654-.493l2.726-9.542A.682.682,0,0,0,92.356,299.11Z"
          transform="translate(-39.97 -279.742)"
          fill="#455a64"
        />
        <path
          id="Path_9980"
          data-name="Path 9980"
          d="M39.238,2.266A9.17,9.17,0,0,0,32.757.011a.682.682,0,0,0-.482,1.164,5.85,5.85,0,0,1,1.011,3.013A5.506,5.506,0,0,0,34.33,7.174a3.471,3.471,0,0,0,4.907-4.907Z"
          transform="translate(0 0)"
          fill="#68b030"
        />
      </g>
    </svg>
  );
}

export default PlantPotIcon;
