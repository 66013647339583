import React from "react";

interface IconProps {
  color?: string;
}

function GiftIcon({ color }: IconProps): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 20H11.25V11.25H8.75V20ZM14.1016 0C12.1164 0 10.7289 1.69336 10 2.87148C9.26953 1.69336 7.88281 0 5.86328 0C3.69063 0 2.5 1.6168 2.5 3.13789C2.5 3.77305 2.70035 4.38359 3.0793 4.9043C3.69258 5.74609 4.75 6.25 5.91016 6.25H8.75V10H11.25V6.25H14.0898C15.2492 6.25 16.3074 5.74687 16.9203 4.90469C17.2994 4.38398 17.4996 3.77305 17.4996 3.13828C17.5 1.6168 16.3086 0 14.1016 0ZM5.91016 4.375C4.84922 4.375 4.375 3.70508 4.375 3.13789C4.375 2.63008 4.77734 1.875 5.86328 1.875C7.03867 1.875 8.11172 3.30078 8.67188 4.375H5.91016ZM14.0898 4.375H11.2937C11.8539 3.30078 12.9266 1.875 14.1023 1.875C15.2227 1.875 15.625 2.63008 15.625 3.13789C15.625 3.70508 15.1523 4.375 14.0898 4.375Z"
        fill={color || "currentColor"}
      />
      <path
        opacity="0.4"
        d="M8.75 11.25V20H2.5C1.80898 20 1.25 19.4414 1.25 18.75V11.25H8.75ZM8.75 10V6.25H5.91016C4.80078 6.25 3.79297 5.77734 3.17031 5H1.25C0.559766 5 0 5.55977 0 6.25V8.75C0 9.4375 0.5625 9.99883 1.21484 9.99883L8.75 10ZM11.25 20H17.5C18.191 20 18.75 19.4414 18.75 18.75L18.75 11.25H11.2852L11.25 20ZM18.75 5H16.8297C16.207 5.77891 15.2 6.25 14.0898 6.25H11.25V10L18.7148 9.9987C19.4023 9.9987 19.9648 9.43737 19.9648 8.74987V6.25C20 5.55859 19.4414 5 18.75 5Z"
        fill={color || "currentColor"}
      />
    </svg>
  );
}

export default GiftIcon;
