import React, { ReactElement } from "react";
interface Props {
  textColor: string;
}

function AppleIcon({ textColor }: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 120.377 51.064"
    >
      <g
        fill={textColor ? textColor : "#fff"}
        data-name="Group 3399"
        transform="translate(-22.436 -29.056)"
      >
        <g data-name="Group 3395">
          <path
            d="M45.186 35.641a9.238 9.238 0 002.125-6.585 9.16 9.16 0 00-6.072 3.144 8.648 8.648 0 00-2.177 6.329 7.643 7.643 0 006.124-2.887"
            data-name="Path 10019"
          ></path>
          <path
            d="M47.285 38.983c-3.382-.2-6.258 1.92-7.873 1.92s-4.089-1.818-6.764-1.769a9.974 9.974 0 00-8.479 5.151c-3.634 6.264-.959 15.555 2.575 20.656 1.716 2.524 3.784 5.3 6.51 5.2 2.575-.1 3.584-1.667 6.714-1.667S44 70.143 46.73 70.092c2.827-.051 4.594-2.525 6.31-5.051a22.419 22.419 0 002.825-5.807 9.172 9.172 0 01-5.5-8.334 9.349 9.349 0 014.441-7.828 9.669 9.669 0 00-7.521-4.089"
            data-name="Path 10020"
          ></path>
        </g>
        <g data-name="Group 3396">
          <path
            d="M76.734 31.944c7.351 0 12.47 5.067 12.47 12.444 0 7.4-5.224 12.5-12.654 12.5h-8.139v12.94H62.53V31.944h14.2zm-8.323 20.005h6.747c5.12 0 8.034-2.756 8.034-7.535s-2.914-7.508-8.007-7.508h-6.774z"
            data-name="Path 10021"
          ></path>
          <path
            d="M90.74 61.979c0-4.831 3.7-7.8 10.266-8.165l7.561-.446v-2.127c0-3.072-2.074-4.91-5.539-4.91-3.283 0-5.33 1.575-5.829 4.043h-5.356c.315-4.989 4.568-8.664 11.394-8.664 6.695 0 10.974 3.544 10.974 9.084v19.034h-5.435v-4.542h-.131a9.868 9.868 0 01-8.716 5.014c-5.408 0-9.189-3.36-9.189-8.321zm17.826-2.494v-2.179l-6.8.42c-3.387.236-5.3 1.733-5.3 4.1 0 2.415 2 3.99 5.041 3.99 3.961-.004 7.059-2.735 7.059-6.331z"
            data-name="Path 10022"
          ></path>
          <path
            d="M119.342 79.989v-4.595a10.5 10.5 0 001.837.1c2.625 0 4.043-1.1 4.909-3.938 0-.053.5-1.68.5-1.707l-9.976-27.646h6.143l6.984 22.474h.1l6.984-22.474h5.986l-10.341 29.069c-2.362 6.7-5.092 8.848-10.816 8.848a21.934 21.934 0 01-2.31-.131z"
            data-name="Path 10023"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default AppleIcon;
